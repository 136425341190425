import React from 'react';

// components
import { Layout } from "../../common";

// assets
import styles from './JoinWaitListThanks.module.scss';
import twitter from "../../../images/icons/twitter.svg";
import discord from "../../../images/icons/discord.svg";
import background from "../../../images/dennis-brian-bg.jpg";

export const JoinWaitListThanks = () => {
  return (
    <Layout title='You’re on the list!' background={background}>
      <div className={styles.thanks}>
        <h1>you’re on <span>the list!.</span>.</h1>
        <h4>Woop Woop! You're now officially on the waitlist!</h4>
        <p>
          We'll be in touch via email shortly with more instructions. In the meantime please join the Discord community
          or follow us on social media below.
        </p>
        <ul>
          <li>
            <a href={'https://discord.gg/GHBH9YhjDU'} target="_blank" rel="noreferrer">
              <img src={discord} alt='' />
            </a>
          </li>
          <li>
            <a href={'https://twitter.com/songbitsfans'} target="_blank" rel="noreferrer">
              <img src={twitter} alt='' />
            </a>
          </li>
        </ul>
      </div>
    </Layout>
  );
};
