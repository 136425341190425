// libs
//import { useNavigate } from "react-router-dom";
//import { routes } from "../../../utils/routes";

// assets
import styles from "./Benefits.module.scss";

export const Benefits = ({ benefits, showModal = () => {} }) => {
  //const navigate = useNavigate();

  return (
    <div className={styles.benefits}>
      <ul>
        {benefits.map((benefit, index) => (
          <li key={index}>
            <img src={benefit.icon} alt='' />
            {benefit.title}
          </li>
        ))}
      </ul>
      
      <button
        className={"btn btn-yellow"}
        onClick={() => {showModal()}}>
        buy <span>now</span>
      </button>
    </div>
  )
}