import React, { useState } from 'react';

// libs
//import { useNavigate } from "react-router-dom";

// components
import { VideoModal } from "../../modals";
import { Benefits, Countdown, Layout } from "../../common";
//import { routes } from "../../../utils/routes";

import SignInModal from "../../modals/SignInModal/SignInModal";

// assets
import styles from './Kayoralpha.module.scss';
import playBtn from "../../../images/icons/play.svg";
import background from "../../../images/dennis-brian-bg.jpg";
import user from "../../../images/icons/user.svg";
import mp3 from "../../../images/icons/mp3.svg";
import money from "../../../images/icons/money.svg";
import ticket from "../../../images/icons/ticket.svg";
import video from "../../../images/icons/video.svg";

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

function dateOrdinal(dom) {
  if (dom === 31 || dom === 21 || dom === 1) {
    return dom + "st";
  }else if (dom === 22 || dom === 2) {
    return dom + "nd";
  } else if (dom === 23 || dom === 3) {
    return dom + "rd";
  } else {
    return dom + "th";
  } 
};

function formatAMPM(date) {
  var hours = date.getUTCHours() + 1; 
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  
  var strTime = hours + ampm;

  return strTime;
}

export const Kayoralpha = () => {
  //const navigate = useNavigate();

  const benefits = [

  ]
  benefits.push({ title: 'Video Hangout with Dennis or Steve for the top 5 buyers. (You choose who) ', icon: video })

  benefits.push({ title: 'First 20 buyers receive a personal Thank You video from Dennis & Steve', icon: video })

  benefits.push({ title: 'First 100 buyers can take part in a Video Hangout Session with Dennis & Steve.', icon: video })
  
  if (process.env.REACT_APP_PERK_TICKET) {
    benefits.push({ title: process.env.REACT_APP_PERK_TICKET, icon: ticket })
  }
  
  if (process.env.REACT_APP_PERK_NFT_IMAGE) {
    benefits.push({ title: 'A unique numbered exclusive image just for you', icon: user })
  }
  
  if (process.env.REACT_APP_PERK_DOWNLOAD) {
    benefits.push({ title: 'A MP3 download of the song to keep', icon: mp3 })
  }
  
  if (process.env.REACT_APP_PERK_ROYALTY) {
    benefits.push({ title: 'A share of the streaming revenues from ‘' + process.env.REACT_APP_SONG_NAME +'’ forever!', icon: money })
  }
  const [showVideo, setShowVideo] = useState(false);
  const [showSignInModal, setShowSignInModal] = useState(false);


  const artist_name = process.env.REACT_APP_ARTIST_NAME.toUpperCase()
  const artist_name_text = process.env.REACT_APP_ARTIST_NAME
  const song_name = process.env.REACT_APP_SONG_NAME

  const title = process.env.REACT_APP_SONG_NAME + ' - ' + process.env.REACT_APP_ARTIST_NAME + ' on SongBits'
  const video_url = process.env.REACT_APP_VIDEO_URL

  const str_date = process.env.REACT_APP_RELEASE_AT 

  let release_date_epoch = ''
  let release_date_string = ''

  if (str_date) {

    const year = parseInt(str_date.substring(0, 4));
    const month = parseInt(str_date.substring(5, 7)) - 1;
    const day = parseInt(str_date.substring(8, 10));
    
    const hour = parseInt(str_date.substring(11, 13));
    const minute = parseInt(str_date.substring(14, 16));
    const second = parseInt(str_date.substring(17, 19));

    const release_date = new Date(Date.UTC(year, month, day, hour - 1, minute, second))
    release_date_epoch = release_date.getTime()
    release_date_string = ' ' + dateOrdinal(release_date.getUTCDate()) + ' ' + monthNames[release_date.getUTCMonth()] + ' ' + release_date.getUTCFullYear() + ' ' + formatAMPM(release_date)

  }

  console.log(release_date_string)
  
  

  return (
    <Layout title={title} artistName={process.env.REACT_APP_ARTIST_NAME} songName={process.env.REACT_APP_SONG_NAME} background={background} noLogo>
      {showVideo && <VideoModal setShowVideo={setShowVideo} src={video_url} />}
      <div className={styles.kayoralpha}>
        <div className={styles.title}>
          <div className={styles.black}>
            <p>ownership release</p>
          </div>
          <h1>{artist_name}</h1>
          <p className={styles.drop}>‘{song_name}’</p>
        </div>
        <div className={styles.content}>
          <div className={styles.left}>
            <p>
              Grab your chance to share in the success of {artist_name_text}'s latest
              song <span className={styles.bold}>‘{song_name}’</span> exclusively for fans released on 5th May 2023 6pm (GMT+1/BST)...
            </p>
            {/*<p>
              <span>How?</span> Join the waitlist below to receive your invite to buy a bit of the song and receive a
              share of the song’s streaming royalties for life plus exclusive VIP benefits!
            </p>
            <p>
              Join the waitlist now below...
              </p>*/}
            <button
              className={"btn btn-yellow"}
              onClick={() => {setShowSignInModal(true)}}>
              buy <span>now</span>
            </button>
            <p>
              <span>Who said VIP?</span> All purchases come with a package of VIP benefits including
            </p>
            <Benefits benefits={benefits} showModal={() => {setShowSignInModal(true)}} />
          </div>
          <div className={styles.right}>
            {video_url ? <><div className={styles.play} onClick={() => setShowVideo(true)}>
              <img src={playBtn} alt="" />
            </div></> : <></>}
            {str_date ? <>
              <p className={styles.yellow}>COUNTDOWN TO SALE </p>
            <div className={styles.timer}>
              <Countdown time={release_date_epoch} />
            </div></> : <></>}
            
          </div>
        </div>
      </div>
      <SignInModal
        show={showSignInModal}
        onClose={() => setShowSignInModal(false)}
        onCreateAccount={() => { 
          window.location.href = 'https://www.songbits.com/create-account?artist=' + process.env.REACT_APP_ARTIST_ID
         }}
        onAlreadyHaveAccount={() => { 
          window.location.href = 'https://www.songbits.com/dennissheperd/summernights'
        }}
      />

    </Layout>
  )
}